<template>
  <div class="pageMain">
    <!-- 企业备案 -->
    <div class="content">
      <!-- 左边列表 -->
      <CustomTables
        :data="tableData"
        
        :total="total"
        class="tableHear"
        :headerCellStyle="headerCellStyle"
        @handleCurrentChanges="handleCurrentChanges"
        @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        :titleIcon="true"
      >
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            :infoShow="true"
            info="项目信息"
            :iconShow="true"
          >
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="" class="formItem">
              <el-input
                v-model="searchForm.projName"
                size="small"
                placeholder="请输入项目名称"
                @input="change"
              ></el-input>
            </el-form-item>
          </el-form>
          </SearchLeft>
        </template>
        <template slot="title">
          <div class="title">今日报警({{ 506 }})</div>
        </template>
        <el-table-column type="index" label="排序" width="50" align="center">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" align="center"> </el-table-column>
        <el-table-column prop="alarmCount" label="总报警(次)" align="center"> </el-table-column>
      </CustomTables>
      <div class="tableBox">
      
      <!--右边列表  -->
    <div class="form">
      <SearchHead
        @search="search2"
        @reset="reset"
        :isExport="true"
        @tableExport="tableExport"
      >
        <el-form :inline="true" :model="searchForm2">
          <el-form-item label="设备类型">
            <el-select
              v-model="searchForm2.deviceType"
              size="small"
              placeholder="设备类型"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="现场编号">
            <el-input
              v-model="searchForm2.user"
              size="small"
              placeholder="现场编号"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="是否消警">
            <el-select
              v-model="searchForm2.alarmStatus"
              size="small"
              placeholder="是否消警"
            >
              <el-option
                v-for="item in alarmStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监控IMEI">
            <el-input
              v-model="searchForm2.deviceImei"
              size="small"
              placeholder="监控IMEI"
            ></el-input>
          </el-form-item>
          <el-form-item label="开始日期">
            <el-date-picker
              v-model="searchForm2.startTime"
              type="datetime"
              placeholder="选择开始日期"
              class="date"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
            class="date"
              v-model="searchForm2.endTime"
              type="datetime"
              placeholder="选择结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </SearchHead>
      <CustomTables
        :data="tableData2"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange2"
      @handleSizeChange="handleSizeChange2"
        height="55vh"
        :total="total2"
        title="告警明细"
      >
      
        <!-- <template slot="btns">
          <el-button type="success" size="small" @click="addFrom"
          icon="el-icon-plus"  class="btn">新建</el-button
          >
        </template> -->
      
        <el-table-column type="selection" align="center" width="55">
    </el-table-column>
        <!-- <el-table-column prop="date" label="现场编号"  align="center"> </el-table-column> -->
        <el-table-column prop="deviceType" label="设备类型"  align="center"> 
          <template slot-scope="scope">
        <span v-if="scope.row.deviceType == 0">塔吊</span>
        <span v-if="scope.row.deviceType == 1">升降机</span>
        <span v-if="scope.row.deviceType == 2">龙门吊</span>
        <span v-if="scope.row.deviceType == 3">架桥机</span>
        <span v-if="scope.row.deviceType == 4">爬模</span>
        <span v-if="scope.row.deviceType == 5">挂篮</span>
      </template>
        </el-table-column>
        <el-table-column prop="deviceImei" label="监控主机IMEI"  align="center" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="alarmCount" label="告警数"  align="center"> </el-table-column>
        
        <el-table-column prop="defusedAlarmCount" label="已消警数"  align="center" > </el-table-column>
        <el-table-column prop="waitingAlarmCount" label="待消警数"  align="center" > </el-table-column>
      </CustomTables>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import data from "../../../api/modules/data";
import {equipType} from '../../../config/dataStatus'
export default {
  components: { CustomTables, SearchHead,SearchLeft },
  name: "companyFilings",
  data() {
    return {
      tableData: [
       
      ],
      tableData2: [
       
       ],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
        currPage: 1,
      pageSize: 10,
      },
      searchForm2: {
        currPage: 1,
      pageSize: 10,
      },
      options: equipType,
      total:0,
      total2:0,
      projId:'',
      alarmStatus:[{label:'已消警',value:0},{label:'待消警',value:1}]
    };
  },
  mounted() {
    this.getProjectList()
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getProjectList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getProjectList()
    },
    handleCurrentChange2(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getAlarmListByPages(this.projId)
    },
    handleSizeChange2(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getAlarmListByPages(this.projId)
    },
    getProjectList(){
      data.getProjTotalListByPage(this.searchForm).then((res) => {
      this.total = res.data.total;
      this.tableData = res.data.records;
     this.projId = res.data.records[0].projId
      this.getAlarmListByPages(this.projId)
    });
    },
    getAlarmListByPages(projId){
      this.searchForm2.projId=projId
      data.getAlarmTotalListByPage(this.searchForm2).then((res) => {
      this.total2 = res.data.total;
      this.tableData2 = res.data.records;
    });
    },
    change(e){
      this.searchForm.projName =e
      this.getProjectList();
    },
    search() {
      console.log("searchForm", this.searchForm);
     
    this.searchForm.currPage = 1;
    this.searchForm.pageSize = 10;
    this.getProjectList();
    },
    search2(){
      this.searchForm2.currPage = 1;
    this.searchForm2.pageSize = 10;
    this.getAlarmListByPages(this.projId)
    },
    reset() {
      this.searchForm2 = {
      currPage: 1,
      pageSize: 10,
     
    };
    this.getAlarmListByPages(this.projId)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleCurrentChanges(row){
    console.log('row',row);
    this.projId = row.projId
    this.getAlarmListByPages(row.projId)
      
  },
    tableExport(){},
    handleSelectionChange(){}
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
}
.tableBox {
  // margin-left: 10px;
  width: 73%;
}
.tableHear {
  width: 25%;
  overflow: auto;
  // background:#e4ebf1
}
.title {
  margin-bottom: 10px;
color: #000;
}

.formItem{
  margin-bottom: 0px;
}
</style>
